<template>
  <div class="mx-8 my-20 lg:container">
    <h2 v-if="heading" class="fluid-xl leading-tight font-medium my-5" data-test-id="heading">{{ heading }}</h2>
    <div class="md:grid md:gap-x-5 md:grid-cols-3 lg:gap-x-24">
      <SmallTopic
        v-for="(topic, index) in smallTopicFeatureTopics"
        :key="index"
        v-bind="topic"
        v-track:view="gtmData(topic, index)"
        v-track:click="gtmData(topic, index)"
      />
    </div>
  </div>
</template>

<script>
import SmallTopic from '@/components/common/blocks/SmallTopic';

export default {
  components: {
    SmallTopic,
  },
  props: {
    smallTopicFeatureTopics: {
      type: Array,
      default: () => [],
    },
    heading: {
      type: String,
      default: null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.topic.url,
        promotion_name: [this.$route?.name, payload.topic?.element?.title].join(' / '),
        creative_name: 'smallTopicFeature',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>

<style scoped>
.cta-arrow {
  @apply lowercase;
}
.cta-arrow::first-letter {
  @apply uppercase;
}
</style>
