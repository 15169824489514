<template>
  <svg
    class="transition duration-200 transform"
    :class="{ 'rotate-180': down }"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 256 256"
  >
    <path d="M225.813 48.907 128 146.72 30.187 48.907 0 79.093l128 128 128-128z" />
  </svg>
</template>

<script>
export default {
  props: {
    down: {
      type: Boolean,
      default: true
    }
  }
}
</script>
