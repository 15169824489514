<template>
  <ProductComparePrice
    v-if="price"
    :compare-at-price="compareAtPrice"
    :price="price"
    :price-range-max="priceRangeMax"
    :price-range-min="priceRangeMin"
    :compare-price-class="comparePriceClass"
    :price-class="priceClass"
  />
</template>

<script>
import gql from 'graphql-tag'
import get from 'lodash/get'
import ProductComparePrice from '@/components/common/ProductComparePrice'

export const ProductPriceByHandleQuery = gql`
  query ProductPriceByHandleQuery($handle: String!) {
    productByHandle(handle: $handle) {
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      variants(first: 1) {
        edges {
          node {
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`

export default {
  components: { ProductComparePrice },

  props: {
    handle: {
      type: String,
      default: undefined
    },
    priceClass: {
      type: String,
      default: undefined
    },
    comparePriceClass: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      price: undefined,
      compareAtPrice: undefined,
      priceRangeMin: undefined,
      priceRangeMax: undefined
    }
  },

  async fetch() {
    if (this.handle) {
      await this.fetchProduct(this.handle)
    }
  },

  methods: {
    fetchProduct(handle) {
      return this.$shopifyRequest(ProductPriceByHandleQuery, { handle })
        .then((data) => {
          this.price = get(data, 'productByHandle.variants.edges[0].node.price')
          this.compareAtPrice = get(data, 'productByHandle.variants.edges[0].node.compareAtPrice')
          this.priceRangeMin = get(data, 'productByHandle.priceRange.minVariantPrice.amount')
          this.priceRangeMax = get(data, 'productByHandle.priceRange.maxVariantPrice.amount')
        })
        .catch((e) => console.error('error fetching Product from shopify', { handle, e }))
    }
  }
}
</script>
