<template>
  <div ref="sliderContainer" class="lg:py-8 relative py-6">
    <div v-if="showArrows" class="slider-arrows-container lg:items-center lg:pb-6 flex items-end justify-between pb-4">
      <!-- <div class="justify-between" /> -->
      <div class="slider-arrows flex justify-end w-full" :class="{ 'justify-between': heading }">
        <h2 v-if="heading" class="pr-4 fluid-3xl leading-tightest">{{ heading }}</h2>
        <!-- <div class='hidden' />-->
        <ArrowBtnNav
          class="block"
          :class="{ hidden: isFirstSlideVisible && isLastSlideVisible }"
          :left-arrow-disabled="isFirstSlideVisible"
          :right-arrow-disabled="isLastSlideVisible"
          @onLeftClick="scrollSlidesLeft"
          @onRightClick="scrollSlidesRight"
        />
      </div>
    </div>
    <div ref="slider" class="slider-root relative flex overflow-x-auto overflow-y-hidden">
      <div ref="trackStart" class="-mr-0.5 track-start" />
      <div
        ref="track"
        class="slider-inner-grid grid grid-flow-col min-h-[200px] pt-4 pb-10 2xl:px-4"
        :style="sliderInnerStyle"
      >
        <slot />
      </div>
      <div ref="trackEnd" class="-ml-0.5 track-end" />
    </div>
    <div ref="sliderContainerReference" />
  </div>
</template>
<script>
import ArrowBtnNav from '@/components/common/ArrowBtnNav';

export default {
  name: 'ResponsiveCardSlider',
  components: {
    ArrowBtnNav,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    breakpoints: {
      type: Object,
      default: () => ({
        sm: {
          minWindowWidth: 0,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        md: {
          minWindowWidth: 768,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        lg: {
          minWindowWidth: 1024,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      }),
    },
  },
  data() {
    return {
      isFirstSlideVisible: true,
      isLastSlideVisible: false,
      isMobile: true,
      isTablet: false,
      containerWidth: null,
      observer: null,
    };
  },
  computed: {
    gutterWidth() {
      return this.containerWidth * 0.05;
    },
    slideWidth() {
      if (this.isMobile) {
        return this.calcSlideWidth(this.breakpoints.sm.slidesToShow);
      } else if (this.isTablet) {
        return this.calcSlideWidth(this.breakpoints.md.slidesToShow);
      } else {
        return this.calcSlideWidth(this.breakpoints.lg.slidesToShow);
      }
    },
    sliderInnerStyle() {
      return {
        gridAutoColumns: (this.slideWidth ? this.slideWidth : 250) + 'px',
        gridColumnGap: this.gutterWidth + 'px',
        paddingLeft: this.gutterWidth / 2 + 'px',
        paddingRight: this.gutterWidth / 2 + 'px',
      };
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.setLayout();
  },
  destroyed() {
    this.observer.disconnect();
    window.removeEventListener('resize', this.handleWindowResize);
  },
  updated() {
    this.$nextTick(function () {
      if (!this.observer && this.$refs.track.childElementCount > 0) this.mountObserver();
    });
  },
  methods: {
    handleWindowResize() {
      this.setLayout();
    },
    calcSlideWidth(slidesToShow) {
      return (this.containerWidth - this.gutterWidth * slidesToShow) / slidesToShow;
    },
    setLayout() {
      const containerRect = this.$refs.sliderContainerReference.getBoundingClientRect();
      this.isMobile = window.innerWidth < this.breakpoints.md.minWindowWidth;
      this.isTablet = window.innerWidth < this.breakpoints.lg.minWindowWidth;
      this.containerWidth = containerRect.width;
    },
    scrollSlidesLeft() {
      this.scrollSlides(-1);
    },
    scrollSlidesRight() {
      this.scrollSlides(1);
    },
    scrollSlides(direction) {
      const slidesCount = this.isMobile
        ? direction * this.breakpoints.sm.slidesToScroll
        : this.isTablet
        ? direction * this.breakpoints.md.slidesToScroll
        : direction * this.breakpoints.lg.slidesToScroll;
      const refSlider = this.$refs.slider;
      const destination = refSlider.scrollLeft + slidesCount * this.slideWidth + slidesCount * (this.gutterWidth / 2);
      refSlider.scrollTo({
        left: destination,
        behavior: 'smooth',
      });
    },
    mountObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target.classList.contains('track-start')) {
              this.isFirstSlideVisible = entry.intersectionRatio === 1;
            }
            if (entry.target.classList.contains('track-end')) {
              this.isLastSlideVisible = entry.intersectionRatio === 1;
            }
          });
        },
        { root: this.$refs.slider, threshold: [0, 1] }
      );
      this.observer.observe(this.$refs.trackStart);
      this.observer.observe(this.$refs.trackEnd);
    },
  },
};
</script>

<style scoped>
.slider-root {
  scroll-snap-type: x proximity;
  scroll-padding-left: 2vw;
}

@screen md {
  .slider-root {
    scroll-padding-left: 2vw;
  }
}

@screen lg {
  .slider-root {
    scroll-padding-left: unset;
  }
}

.slider-root::-webkit-scrollbar {
  @apply hidden;
}

.slider-inner-grid {
  grid-auto-columns: 50vw;
}

.slider-inner-grid > * {
  scroll-snap-align: start;
}

@screen lg {
  .slider-inner-grid {
    grid-auto-columns: 33vw;
  }

  .slider-inner-grid > * {
    /* @apply snap-center; */
    scroll-snap-align: center;
  }
}
</style>
