import { render, staticRenderFns } from "./GridBlock.vue?vue&type=template&id=aae7a630"
import script from "./GridBlock.vue?vue&type=script&lang=js"
export * from "./GridBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports