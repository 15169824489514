<template>
  <div class="container flex my-20 flex-col md:w-2/3 xl:w-3/5 md:mx-auto relative">
    <div id="collection-information" class="absolute -top-2" />
    <h2 class="mb-8 fluid-xl leading-tight font-medium">{{ heading }}</h2>
    <div class="text-copy text-body-2 w-full mb-4" data-test-id="shortDescription" v-html="shortDescription"></div>
    <Expandable class="w-full" :open="open" :duration="300">
      <div class="text-copy text-body-2 w-full" data-test-id="longDescription" v-html="longDescription"></div>
    </Expandable>
    <div class="h-0">
      <button
        v-if="!open && longDescription"
        class="focus:outline-none mt-2 text-br-green font-light"
        @click="open = !open"
      >
        + {{ $t('readMore') }}
      </button>
    </div>
  </div>
</template>

<script>
import Expandable from '@/components/common/Expandable';

export default {
  name: 'CollectionInformation',
  components: { Expandable },
  props: {
    heading: {
      type: String,
      default: null,
    },
    shortDescription: {
      type: String,
      default: null,
    },
    longDescription: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
