<template>
  <nuxt-link
    v-if="hasNextPage"
    ref="link"
    class="btn-pill-outline-black m-4"
    :class="{ 'pointer-events-none': loading }"
    :to="routeTo"
    data-testid="btn-product-grid-load-more"
    @click="observe"
    >{{ $t('collection.loadMore') }}</nuxt-link
  >
</template>

<script>
import { isElement } from '@/utils/common'

/**
 * loadMore button renders link to same page with query :limit param
 * observes scroll position, triggers route change with query-param :limit if button is 2 x screen height away from view
 * hides button if no more products to load
 * prevents click-events if loading is already triggered
 */
export default {
  props: {
    productsConnection: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    hasNextPage() {
      return this.productsConnection?.pageInfo?.hasNextPage
    },
    routeTo() {
      const { collectionProductsPerPage } = this.$config
      return { query: { limit: this.productCount + collectionProductsPerPage } }
    },
    productCount() {
      return this.productsConnection?.edges.length || 0
    }
  },
  watch: {
    productCount() {
      this.resetObserve()
    }
  },
  mounted() {
    // trigger click if window scrollposition is one window-height away
    this.mountObserver()
    this.observe()
    this.$root.$on('disable-infinity-loading', this.unobserve)
  },
  beforeDestroy() {
    this.$root.$off('disable-infinity-loading', this.unobserve)
    this.observer.disconnect()
  },
  methods: {
    mountObserver() {
      const options = {
        rootMargin: `${window.innerHeight * 1.5}px`,
        threshold: 0.1
      }
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && this.hasNextPage) {
            this.$router.replace(this.routeTo)
          }
        })
      }
      this.observer = new IntersectionObserver(callback, options)
    },
    observe() {
      if (this.hasNextPage && isElement(this.$el)) this.observer.observe(this.$el)
    },
    unobserve() {
      if (isElement(this.$el)) this.observer.unobserve(this.$el)
    },
    resetObserve() {
      this.loading = false
      this.unobserve()
      // start observing after rerender of products
      this.$nextTick(() => {
        this.observe()
      })
    }
  }
}
</script>
