<template>
  <div class="flex flex-col justify-center items-center h-full bg-white md:box-content">
    <div class="relative flex flex-col two-by-two-image-cta w-[75%] h-[65%]">
      <CraftAsset
        v-if="image[0]"
        class="object-cover w-full h-full hidden md:block"
        :ratio="1.19"
        crop="fill"
        sizes="(min-width: 1440px) 638px, 50vw"
        :asset="image[0]"
        :alt="alt"
        autoplay
        muted
      />
      <CraftAsset
        v-if="image[0]"
        class="object-cover w-full h-1/2 block md:hidden"
        :ratio="2 / 3"
        crop="fill"
        sizes="100vw"
        :asset="image[0]"
        :alt="alt"
        autoplay
        muted
      />
      <div class="md:absolute h-full md:h-auto align-center bottom-0 md:p-4">
        <div class="box h-full md:h-auto flex flex-col justify-center">
          <BarText v-if="heading" tag="h2" class="mb-5" :text="heading" data-test-id="header" />
          <CtaArrow v-if="cta" v-bind="cta" class="cta-arrow font-medium" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import CtaArrow from '@/components/common/CtaArrow';
import CraftAsset from '@/components/common/CraftAsset';
import { CraftAssetFragment } from '@/components/common/CraftAssetFragment.gql';
import BarText from '@/components/BarText';
export const GridBlocksTwoByTwoImageCtaFragment = gql`
  fragment GridBlocksTwoByTwoImageCtaFragment on gridBlocks_twoByTwoImageCta_BlockType {
    typeHandle
    position
    image {
      ...CraftAssetFragment
    }
    alt
    heading
    cta {
      ...LinkFragment
    }
  }
`;
export default {
  components: { CraftAsset, BarText, CtaArrow },
  cols: 2,
  rows: 2,
  props: {
    position: {
      type: Number,
      default: null,
    },
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .two-by-two-image-cta {
    @apply w-full h-full;
  }
}
</style>
