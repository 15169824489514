<template>
  <div class="container text-center flex flex-col items-center my-12">
    <h1 class="mb-4 fluid-4xl leading-none">{{ heading }}</h1>
    <div
      class="text-copy fluid-base leading-relaxed font-light w-full md:w-3/5"
      data-test-id="teaserText"
      v-html="teaserText"
    ></div>
    <button
      v-if="showScrolldown"
      data-testid="btn-scroll-to-information"
      class="focus:outline-none text-br-green mt-2 font-light"
      @click="moveToCollectionInformation"
    >
      + {{ $t('readMore') }}
    </button>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { scrollToSelectorRespectingBars } from '~/utils/common'


export default {
  name: 'CollectionTitle',
  props: {
    heading: {
      type: String,
      default: null
    },
    teaserText: {
      type: String,
      default: null
    },
    showScrolldown: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    moveToCollectionInformation() {
      const anchor = document.querySelector('#collection-information')
      if (anchor) {
        this.$root.$emit('disable-infinity-loading')
        scrollToSelectorRespectingBars('#collection-information')
      }
    }
  }
}
</script>
