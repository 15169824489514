<template>
  <div class="my-l md:mx-8 lg:container lg:relative mx-3">
    <h2 v-if="mainHeading" class="fluid-xl leading-tight font-medium my-5" data-test-id="main-heading">
      {{ mainHeading }}
    </h2>
    <CraftAsset
      v-if="image[0]"
      :asset="image[0]"
      :alt="alt"
      :ratio="2 / 3"
      crop="fill"
      sizes="100vw"
      class="block object-cover w-full"
      autoplay
      muted
    />
    <div
      class="lg:-translate-y-1/2 bg-blackly pb-14 lg:absolute top-1/2 left-12 xl:left-16 lg:w-2/5 lg:px-10 xl:px-14 xl:pt-20 xl:pb-24 px-5 pt-10 text-white"
    >
      <h2 v-if="heading" class="fluid-4xl leading-none">{{ heading }}</h2>
      <div v-if="body" class="fluid-base leading-relaxed font-light md:pb-2 lg:py-5 pt-4 pb-1" v-html="body"></div>
      <LinkField v-bind="cta" class="btn-pill-black mt-5" />
    </div>
  </div>
</template>

<script>
import LinkField from '@/components/common/LinkField'
import CraftAsset from '@/components/common/CraftAsset'
export default {
  components: {
    LinkField,
    CraftAsset
  },
  props: {
    mainHeading: {
      type: String,
      default: null
    },
    heading: {
      type: String,
      default: null
    },
    body: {
      type: String,
      default: null
    },
    image: {
      type: Array,
      default: () => []
    },
    alt: {
      type: String,
      default: null
    },
    cta: {
      type: Object,
      default: () => null
    }
  }
}
</script>
