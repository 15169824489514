<template>
  <div class="text-white mx-3 my-20 md:grid md:grid-rows-2 md:grid-cols-3 md:gap-5 lg:gap-8 lg:container">
    <div class="row-span-2 col-span-2 md:relative">
      <FixedAspect :ratio="0.6" class="product-picture w-full h-full">
        <CldnImg
          v-if="mainImage[0]"
          :src="mainImage[0].url"
          :alt="mainImageAlt ? mainImageAlt : mainImage[0].title"
          crop="fill"
          sizes="(min-width: 1440px) 853px, (min-width: 768px) 70vw, 100vw"
        />
      </FixedAspect>
      <div class="bg-blackly px-8 py-10 md:py-8 md:absolute md:bottom-4 md:left-4 md:w-2/3 lg:left-8 lg:px-10">
        <h2 v-html="mainHeading"></h2>
        <ProductComparePriceByHandle
          v-if="handle"
          :handle="handle"
          class="py-5"
          price-class="fluid-lg leading-tight font-medium"
          compare-price-class="fluid-sm tracking-wider font-medium sm:ml-3"
        />
        <LinkField v-bind="mainCta" class="btn-pill-black" />
      </div>
    </div>
    <div class="relative my-8 md:my-0">
      <FixedAspect :ratio="0.68" class="product-picture w-full h-full">
        <CldnImg
          v-if="secondaryImage[0]"
          :src="secondaryImage[0].url"
          :alt="secondaryAlt"
          sizes="(min-width: 1440px) 410px, (min-width: 768px) 30vw, 100vw"
          :ratio="2 / 3"
          crop="fill"
        />
      </FixedAspect>
      <barText class="absolute bottom-5 left-5 w-min p-1 pr-3" tag="h3" :text="secondaryHeading" />
    </div>
    <div class="bg-blackly px-5 py-8">
      <h3 class="w-2/3 mb-16" v-html="tertiaryHeading"></h3>
      <CtaArrow v-bind="tertinaryCta" />
    </div>
  </div>
</template>

<script>
import LinkField from '@/components/common/LinkField';
import CldnImg from '@/components/CldnImg';
import CtaArrow from '@/components/common/CtaArrow';
import BarText from '@/components/BarText';
import FixedAspect from '@/components/common/FixedAspect';
import ProductComparePriceByHandle from '@/components/common/ProductComparePriceByHandle';

export default {
  components: { ProductComparePriceByHandle, FixedAspect, BarText, CtaArrow, CldnImg, LinkField },
  props: {
    mainHeading: {
      type: String,
      default: null,
    },
    mainCta: {
      type: Object,
      default: () => null,
    },
    mainImage: {
      type: Array,
      default: () => [],
    },
    mainImageAlt: {
      type: String,
      default: null,
    },
    secondaryHeading: {
      type: String,
      default: null,
    },
    secondaryImage: {
      type: Array,
      default: () => [],
    },
    secondaryAlt: {
      type: String,
      default: null,
    },
    tertiaryHeading: {
      type: String,
      default: null,
    },
    tertinaryCta: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    handle() {
      const elem = this.mainCta?.element;
      return elem?.sectionHandle === 'products' ? elem.shopifyHandle : null;
    },
  },
};
</script>

<style scoped>
.product-picture >>> * {
  @apply w-full h-full;
}
</style>
