<template>
  <div class="compare-table container py-16">
    <div class="sm:flex-row flex flex-col items-start justify-between border-b border-gray-300 p-4">
      <div v-if="showFilters && filterOptions" class="flex flex-nowrap relative items-start">
        <div
          class="fluid-lg font-light leading-normal font-medium flex flex-nowrap items-center"
          @click="filtersVisible = !filtersVisible"
        >
          <span>{{ $t('compare.bestFor') }}<span class="hidden md:inline md:mr-2">:</span></span>
          <DownChevronIcon :down="filtersVisible" class="block md:hidden w-3 h-3 ml-2" />
        </div>
        <div
          class="md:block absolute md:static top-5 z-10 bg-white border border-gray-400 p-3 md:border-none md:p-0 divide-y md:divide-y-0"
          :class="[filtersVisible ? 'block' : 'hidden']"
        >
          <span
            v-for="(option, index) in filterOptions"
            :key="index"
            class="cursor-pointer fluid-sm tracking-wider font-medium block md:inline-block p-2 md:py-1"
            :class="[currentFilter === option ? 'filter-selected' : '']"
            @click="filterBy(option)"
          >
            {{ option }}
          </span>
          <span
            class="cursor-pointer fluid-sm tracking-wider font-medium block md:hidden p-2 md:p-0"
            @click="filterBy(null)"
            >{{ $t('compare.reset') }}</span
          >
        </div>
      </div>
      <h2 v-else class="mr-auto fluid-xl leading-tight font-medium w-3/4 md:w-full break-words">{{ heading }}</h2>
    </div>
    <div class="compare-table flex flex-row flex-nowrap mt-5">
      <div class="divide-y hidden md:block w-1/4 bg-putty p-5 text-copy mb-10 mr-2">
        <div class="px-5 mt-12">
          <FixedAspect :ratio="0.55" />
        </div>
        <div
          v-for="attribute in compareAttributes"
          :key="attribute.key"
          class="flex flex-col py-4"
          :style="{ height: attribute.height }"
        >
          <div class="flex flex-nowrap">
            <strong class="font-medium leading-3">{{ attribute.title }}</strong>
            <span v-if="attribute.info" class="relative group flex items-center">
              <InfoIcon class="text-blackly cursor-help ml-2 h-4" />
              <span
                class="absolute top-6 left-1/2 translate-x-[-50%] hidden group-hover:block bg-white p-4 shadow-lg w-48 z-10"
              >
                >{{ attribute.info }}</span
              >
            </span>
          </div>
        </div>
        <div class="flex flex-nowrap py-5">
          <strong>{{ $t('compare.variants') }}</strong>
        </div>
      </div>
      <div class="w-full md:w-3/4 relative">
        <ResponsiveCardSlider :items="filteredProductList" :breakpoints="breakpoints" class="compare-list">
          <ProductCompareCardLarge
            v-for="product in filteredProductList"
            ref="slides"
            :key="product.id"
            :product="product"
            :compare-attributes="compareAttributes"
            :row-heights="maxRowHeights"
            data-test-id="item"
            class="shadow-xl p-5"
            @rowHeightsCalculated="onCardRowHeights"
          />
        </ResponsiveCardSlider>
      </div>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import FixedAspect from '@/components/common/FixedAspect';
import ResponsiveCardSlider from '@/components/common/ResponsiveCardSlider';
import ProductCompareCardLarge from '@/components/shop/products/ProductCompareCardLarge';
import InfoIcon from '@/components/icons/InfoIcon';
import { productMetaFieldTransformer } from '@/components/shop/products/Product';
import { collectionMetaFieldTransformer } from '@/components/shop/collections/Collection';
import DownChevronIcon from '@/components/icons/DownChevronIcon';


export default {
  name: 'CompareTable',
  components: {
    InfoIcon,
    ResponsiveCardSlider,
    ProductCompareCardLarge,
    FixedAspect,
    DownChevronIcon,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    collectionHandle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      breakpoints: {
        sm: {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        md: {
          minWindowWidth: 720,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        lg: {
          minWindowWidth: 1024,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      currentFilter: null,
      compareCollection: [],
      filtersVisible: false,
      slides: [],
      rowHeights: [],
    };
  },
  async fetch() {
    if (this.collectionHandle) {
      await this.fetchProducts();
    }
  },
  computed: {
    compareAttributes() {
      if (this.compareCollection?.collection) collectionMetaFieldTransformer(this.compareCollection?.collection);
      return (
        this.compareCollection?.collection?.comparisonHeaders?.map((header, index) => {
          return { ...header, height: this.maxRowHeights[index] + 'px' };
        }) || []
      );
    },
    productList() {
      const products = this.compareCollection?.collection?.products?.edges.map((edge) => edge.node) || [];
      return products.map((product) => {
        return productMetaFieldTransformer(product);
      });
    },
    filteredProductList() {
      if (this.currentFilter && this.productList.length) {
        return this.productList.filter((product) => {
          return product.comparison.bestFor?.includes(this.currentFilter);
        });
      }
      return this.productList;
    },
    filterOptions() {
      const filters = this.productList && this.productList.map((e) => e.comparison?.bestFor).filter(Boolean);
      return [...new Set(filters.flat())];
    },
    maxRowHeights() {
      const transposed = this.rowHeights[0]?.map((col, colIndex) => this.rowHeights.map((row) => row[colIndex]));
      return transposed?.map((e) => Math.max(...e)) || [];
    },
  },
  methods: {
    async fetchProducts() {
      try {
        this.compareCollection = await this.$shopifyComparisonRequest({ handle: this.collectionHandle });
      } catch (error) {
        this.$sentry.captureException(error);
      }
    },
    filterBy(option) {
      this.filtersVisible = false;
      if (option === this.currentFilter) this.currentFilter = null;
      else this.currentFilter = option;
    },
    onCardRowHeights(rowHeights) {
      this.rowHeights.push(rowHeights);
    },
  },
};
</script>

<style>
.compare-table .slider-arrows-container {
  @apply absolute right-5 top-[-55px];
  /* top: -55px; */
}
.compare-list {
  @apply py-0;
}
.compare-list .slider-inner-grid {
  @apply pt-0;
}
.filter-selected {
  @apply underline md:no-underline md:rounded-full md:bg-black md:text-white font-bold md:font-normal;
}
</style>
