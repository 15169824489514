<template>
  <div
    ref="productGrid"
    data-testid="productGrid"
    class="lg:grid-cols-3 mx-1 sm:mx-0 sm:container product-grid xl:gap-10 grid grid-flow-row-dense grid-cols-2 gap-4 sm:gap-6 my-20 mb-12"
  >
    <Gridblock v-for="(block, idx) in gridBlocks" :key="`gridblocks_${idx}`" :block="block" />
    <ProductCard
      v-for="product in productsWithPosition"
      :key="product.handle"
      :product="product"
      :style="{ order: product.position }"
    />
    <div class="col-span-full flex items-center justify-center" style="order: 999">
      <ProductGridInfinityMoreBtn :products-connection="productsConnection" />
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/shop/products/ProductCard';
import Gridblock from '@/components/shop/collections/gridBlocks/GridBlock';
import ProductGridInfinityMoreBtn from '@/components/common/blocks/ProductGrid/ProductGridInfinityMoreBtn';

/**
 * renders grid of girdBlocks and Products
 * all gridBlocks are rendered before ProductCards
 * order of blocks is acchieved by setting position attribute on all grid-items
 * gridBlocks come with fixed-position attribute
 * productCards get position calculated spared out gridBlock-positions
 */
export default {
  components: {
    ProductCard,
    Gridblock,
    ProductGridInfinityMoreBtn,
  },
  props: {
    gridBlocks: {
      type: Array,
      default: () => [],
    },
    productsConnection: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    gridBlockPositions() {
      return this.gridBlocks.map((block) => block.position);
    },
    products() {
      return this.productsConnection?.edges?.map((edge) => edge.node) || [];
    },
    /**
     * add position to product , spare out all positions taken by gridBlocks
     * @returns {Array} - Array of collection-products with attribute position
     */
    productsWithPosition() {
      let i = 1;
      return this.products.map((product) => {
        while (this.gridBlockPositions.includes(i)) {
          i++;
        }
        product.position = i;
        i++;
        return product;
      });
    },
  },
};
</script>

<style scoped>
.product-grid {
  grid-template-rows: minmax(0, 1fr);
}
</style>
