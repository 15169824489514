<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="block"
        :products-connection="block.typeHandle === 'productGrid' && collection ? collection.products : null"
        :grid-blocks="gridBlocks"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import guestComponent from '@/mixins/guestComponent';

import ProductListingFeature, {
  ShopBlocksProductListingFeatureFragment,
} from '@/components/common/blocks/ProductListingFeature';
import AsSeenIn, { ShopBlocksAsSeenInFragment } from '@/components/common/blocks/AsSeenIn';
import ImageTitleFeature, { ShopBlocksImageTitleFeatureFragment } from '@/components/common/blocks/ImageTitleFeature';
import ProductSlider, { ShopBlocksProductSliderFragment } from '@/components/common/blocks/ProductSlider';
import LargeImageFeature, { ShopBlocksLargeImageFeatureFragment } from '@/components/common/blocks/LargeImageFeature';
import CollectionsSlider, { ShopBlocksCollectionsSliderFragment } from '@/components/common/blocks/CollectionsSlider';
import FullWidthTeaserBox, {
  ShopBlocksFullWidthTeaserBoxFragment,
} from '@/components/common/blocks/FullWidthTeaserBox';
import ProductFeatureThree, {
  ShopBlocksProductFeatureThreeFragment,
} from '@/components/common/blocks/ProductFeatureThree';
import SmallTopicFeature, {
  ShopBlocksSmallTopicFeatureFragment,
} from '@/components/common/blocks/SmallTopicFeature/index';
import AppCta, { ShopBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import ProductGrid, { ShopBlocksProductGridFragment } from '@/components/common/blocks/ProductGrid';
import ExerciseRoutineCardsSliderWithText, {
  ShopBlocksRoutineCardsSliderWithTextFragment,
} from '@/components/common/blocks/ExerciseRoutineCardsSliderWithText';
import ContentRecommendationSlide, {
  ShopBlocksContentRecommendationSlideFragment,
} from '@/components/common/blocks/ContentRecommendationSlide';
import CollectionTitle, { ShopBlocksCollectionTitleFragment } from '@/components/common/blocks/CollectionTitle';
import CollectionInformation, {
  ShopBlocksCollectionInformationFragment,
} from '@/components/common/blocks/CollectionInformation';
import CenteredContent from '@/components/common/blocks/CenteredContent';
import { ShopBlocksCenteredContentFragment } from '@/components/common/blocks/CenteredContent/index';
import ImageText, { ShopBlocksImageTextFragment } from '@/components/common/blocks/MediaText';
import CompareContentSlider, {
  ShopBlocksCompareContentSliderFragment,
} from '@/components/common/blocks/CompareContentSlider';
import CompareTable, { ShopBlocksCompareTableFragment } from '~/components/common/blocks/CompareTable';
import GenericIframe, { ShopBlocksGenericIframeFragment } from '@/components/common/blocks/GenericIframe';
import ImageOrVideo from '@/components/common/blocks/ImageOrVideo';
import { ShopBlocksImageOrVideoFragment } from '@/components/common/blocks/ImageOrVideo/index';
import NewsletterSignupForm, {
  ShopBlocksNewsletterSignupFormFragment,
} from '@/components/common/blocks/NewsletterSignupForm';

export const ShopBlocksMatrixFragment = gql`
  fragment ShopBlocksMatrixFragment on shopBlocks_MatrixField {
    ...ShopBlocksProductListingFeatureFragment
    ...ShopBlocksAsSeenInFragment
    ...ShopBlocksImageTitleFeatureFragment
    ...ShopBlocksProductSliderFragment
    ...ShopBlocksLargeImageFeatureFragment
    ...ShopBlocksCollectionsSliderFragment
    ...ShopBlocksFullWidthTeaserBoxFragment
    ...ShopBlocksProductFeatureThreeFragment
    ...ShopBlocksSmallTopicFeatureFragment
    ...ShopBlocksAppCtaFragment
    ...ShopBlocksProductGridFragment
    ...ShopBlocksRoutineCardsSliderWithTextFragment
    ...ShopBlocksContentRecommendationSlideFragment
    ...ShopBlocksCollectionTitleFragment
    ...ShopBlocksCollectionInformationFragment
    ...ShopBlocksCenteredContentFragment
    ...ShopBlocksImageTextFragment
    ...ShopBlocksCompareContentSliderFragment
    ...ShopBlocksCompareTableFragment
    ...ShopBlocksGenericIframeFragment
    ...ShopBlocksImageOrVideoFragment
    ...ShopBlocksNewsletterSignupFormFragment
  }
  ${ShopBlocksProductListingFeatureFragment}
  ${ShopBlocksAsSeenInFragment}
  ${ShopBlocksImageTitleFeatureFragment}
  ${ShopBlocksProductSliderFragment}
  ${ShopBlocksLargeImageFeatureFragment}
  ${ShopBlocksCollectionsSliderFragment}
  ${ShopBlocksFullWidthTeaserBoxFragment}
  ${ShopBlocksProductFeatureThreeFragment}
  ${ShopBlocksSmallTopicFeatureFragment}
  ${ShopBlocksAppCtaFragment}
  ${ShopBlocksProductGridFragment}
  ${ShopBlocksRoutineCardsSliderWithTextFragment}
  ${ShopBlocksContentRecommendationSlideFragment}
  ${ShopBlocksCollectionTitleFragment}
  ${ShopBlocksCollectionInformationFragment}
  ${ShopBlocksCenteredContentFragment}
  ${ShopBlocksImageTextFragment}
  ${ShopBlocksCompareContentSliderFragment}
  ${ShopBlocksCompareTableFragment}
  ${ShopBlocksGenericIframeFragment}
  ${ShopBlocksImageOrVideoFragment}
  ${ShopBlocksNewsletterSignupFormFragment}
`;

export default {
  name: 'ShopBlocks',
  components: {
    GenericIframe,
    CompareContentSlider,
    ImageText,
    CenteredContent,
    FullWidthTeaserBox,
    ProductListingFeature,
    AsSeenIn,
    ImageTitleFeature,
    ProductSlider,
    LargeImageFeature,
    CollectionsSlider,
    ProductFeatureThree,
    SmallTopicFeature,
    AppCta,
    ProductGrid,
    ExerciseRoutineCardsSliderWithText,
    ContentRecommendationSlide,
    CollectionTitle,
    CollectionInformation,
    CompareTable,
    ImageOrVideo,
    NewsletterSignupForm,
  },
  mixins: [guestComponent],
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
    gridBlocks: {
      type: Array,
      default: () => [],
    },
    collection: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
